import * as React from "react"
import { useRef, useEffect } from "react"
import OctoPoster from "../images/ML_Website_Animation_v3.jpg"
import DownArrow from "../images/svg/arrow-down.svg"
import PropTypes from "prop-types"

// import Octo from "../images/svg/octopus.svg"

// octo animation will be updated, for now using a static version that's still an mp4

const OctoIntro = ({ handleOcto, octoShow }) => {
  const octoVid = useRef(null)

  const playOcto = () => {
    octoVid.current.play()
  }

  useEffect(() => {
    if (!octoShow && octoVid) {
      playOcto()
    }
  }, [octoShow])

  return (
    <div
      className={`octo-intro ${!octoShow ? "octo-intro--offscreen" : ""}`}
      onClick={() => {
        handleOcto()
        playOcto()
      }}
      onKeyDown={() => {
        handleOcto()
        playOcto()
      }}
      role="button"
      aria-label="Octopus Intro"
      tabIndex={-1}
    >
      <div className="octo">
        <video ref={octoVid} muted playsInline preload="metadata">
          <source
            type="video/mp4"
            src={"/Octopus_default_horizontal.mp4#t=0.01"}
            poster={OctoPoster}
          />
        </video>
        {/* static version */}
        {/* <Octo /> */}
      </div>

      <button
        aria-label="Down Arrow"
        className={`down-arrow ${!octoShow ? "down-arrow--hide" : ""}`}
      >
        <DownArrow />
      </button>
    </div>
  )
}

OctoIntro.propTypes = {
  handleOcto: PropTypes.func.isRequired,
  octoShow: PropTypes.bool,
}

export default OctoIntro
