import * as React from "react"
import { useState, useEffect, useRef } from "react"
import Media from './media'
import CheckDevice from './checkMobile'
import { Link } from 'gatsby'

const FeaturedGrid = ({ workItems }) => {
  const ref = useRef(null);
  const [isMobile, setIsMobile] = useState(CheckDevice);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    const element = ref.current;
    const featuredItemArray = element.querySelectorAll('.featured-grid__item');
    const imgOdd = element.querySelectorAll('.featured-grid__item:nth-child(odd)');
    const imgEven = element.querySelectorAll('.featured-grid__item:nth-child(even)');
    
    const handlePageResized = () => {
      setIsMobile(CheckDevice);
    };

    window.addEventListener('resize', handlePageResized);
    if(isMobile ) {
      featuredItemArray.forEach(function (item){
          item.style.flex = `1 1 ${getRandomInt(34,45)}%`;
      });

      for (var m = 0; m < featuredItemArray.length; ++m) {
        let lag = 0.2 //initial value
        featuredItemArray[m].setAttribute('data-speed', 1.1);
        featuredItemArray[m].setAttribute('data-lag', m * lag);
      }
    }

    imgOdd.forEach(function(i){
      if(isMobile) {
        i.style.left = `${getRandomInt(0,10)}%`;
        i.style.marginBottom = `${getRandomInt(0,20)}px`;
      }
    });

    imgEven.forEach(function(i){
      if(isMobile) {
        i.style.right = `${getRandomInt(0,10)}%`;
        i.style.marginTop = `${getRandomInt(-10,50)}px`;
      }
    });



    return () => {
      window.removeEventListener('resize', handlePageResized);
    };

  }, [isMobile]);



  return (
    <section className='featured-grid' ref={ref}>     
      {workItems.map((item, i) => {
        const image = item.hoverFileType === 'video' 
        ? (item.mobileImage?.image || item.hoverVideo?.posterImage) 
        : item.hoverImage.image
        
        return (
        <Link 
          to={`/work/${item.slug.current}`} 
          key={item.id} 
          className='featured-grid__item'
        >
          <Media 
            className='media-wrap media-wrap--6-4'
            mediaType='image'
            image={image}
            altText={item.allWorkMobileImage?.altText}
            title={item.title}
          />
          {!isMobile && <h2 className='featured-grid__title'>{item.title}</h2> }
        </Link>
      )})}
    </section>
  ) 
}

export default FeaturedGrid